import React from "react";
import { useTranslation } from "react-i18next";
import PLATF_LOGO from "../../../assets/images/platforma.png";
import PLATF_KONSULT from "../../../assets/images/platforma_konsultimit.png";
//import PACEP from "../../../assets/images/pacep.png";
// import KUV_PER_FEMIJET from "../../../assets/images/kuv_per_femijet.png";
import STRATEG_DOCS from "../../../assets/images/strategyDocument.jpg";
import PROJECT_ICON from "assets/images/projectIcon.png";
import { SimpleCards } from "components/GlobalComponents/SimpleCards/SimpleCards";

export const Platforma = () => {
  const [t] = useTranslation();
  const cardData = [
    {
      text: t("homeSections.platformSection.interinstitutionalPlatform"),
      icon: PLATF_LOGO,
      goTo: "http://134.0.63.165:5000/public",
    },
    // {
    //   text: "Kuvendi për fëmijët",
    //   icon: KUV_PER_FEMIJET,
    //   goTo: "",
    // },
    {
      text: t("homeSections.homeHeader.projects"),
      icon: PROJECT_ICON,
      goTo: "https://parlament.al/struktura/82946470-06ae-497e-93fe-29806495234a",
    },
    {
      text: t("homeSections.homeHeader.consPlatform"),
      icon: PLATF_KONSULT,
      goTo: "https://konsultimi.parlament.al/",
    },
    // {
    //   text: "",
    //   icon: PACEP,
    //   bigger: true,
    //   goTo: "",
    // },
    {
      text: t("homeSections.homeHeader.strategicDocs"),
      icon: STRATEG_DOCS,
      goTo: "https://parlament.al/struktura/34c3477b-65cf-4682-b8aa-dd4b528b88aa",
    },
    // {
    //   text: t("homeSections.platformSection.interinstitutionalPlatform"),
    //   icon: PLATF_LOGO,
    // },
  ];
  return <SimpleCards {...{ cardData }} />;
};
